import "./App.scss";
import axios from "axios";

function App() {
  const handleButton = async (message) => {
    const params = {
      content: message,
    };
    await axios.post(process.env.REACT_APP_WEBHOOK, params);
  };
  return (
    <div className="App">
      <div className="buttons">
        <button onClick={() => handleButton(`>skip`)}>Skip</button>
        <button onClick={() => handleButton(`>clear`)}>Clear</button>
      </div>
    </div>
  );
}

export default App;
